import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { createFullDateString, getGradCollection } from "./appUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const gradCollection = getGradCollection();

const headerPruefungsliste = [
  "NR:",
  "Grad",
  "Standort",
  "M.-Nummer",
  "Nachname",
  "Vorname",
  "Geburtsdatum",
  "Pass",
  "Bezahlt",
  "Bestanden",
];

export const makePruefungslistPDFfromContent = (
  data,
  selectedEvent,
  standortLookup
) => {
  let tH = headerPruefungsliste.map((h) => {
    return { text: h, fontSize: 10, bold: true };
  });

  let dataRows = [];
  data.forEach((h, i) => {
    let newRow = [
      { text: `${i + 1}`, fontSize: 10 },
      {
        text: `${gradCollection[h.degree - 1].title}`,
        fontSize: 10,
        noWrap: true,
      },
      {
        text: `${standortLookup && standortLookup[h.standortRef]}`,
        fontSize: 10,
        noWrap: true,
      },
      { text: `${h.memberNumber}`, fontSize: 10 },
      { text: `${h.lastName}`, fontSize: 10 },
      { text: `${h.firstName}`, fontSize: 10 },
      {
        text: `${
          h.birthdate ? createFullDateString(new Date(h.birthdate)) : ""
        }`,
        fontSize: 10,
      },
      {
        text: `${h.hasPassport ? "JA" : ""}`,
        fontSize: 10,
        alignment: "center",
      },
      {
        text: `${h.hasPayedTesting ? "JA" : ""}`,
        fontSize: 10,
        alignment: "center",
      },
      {
        text: `${h.hasPassedTesting ? "JA" : ""}`,
        fontSize: 10,
        alignment: "center",
      },
    ];
    dataRows.push(newRow);
  });

  dataRows.unshift(tH);

  // Docu: https://pdfmake.github.io/docs/0.1/
  var docDefinition = {
    content: [
      { text: "Prüfungsliste", style: "header" },
      {
        text: `Name: ${selectedEvent.eventName}`,
        style: "subheader",
      },
      `Datum: ${createFullDateString(new Date(selectedEvent.eventDateBegin))}`,
      {
        style: "tableExample",
        table: {
          body: dataRows,
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
    defaultStyle: {
      // alignment: 'justify'
    },
  };
  pdfMake.createPdf(docDefinition).open();
};

const headerMitgliederliste = [
  "NR:",
  "Grad",
  "Vertragsdatum",
  "M.-Nummer",
  "Nachname",
  "Vorname",
  // "letzte Prüfung",
  "Geburtsdatum",
  "Kommentar",
];

export const makeStandortlistPDFfromContent = (data, siteName) => {
  let tH = headerMitgliederliste.map((h) => {
    return { text: h, fontSize: 10, bold: true };
  });

  let dataRows = [];
  data.forEach((h, i) => {
    let newRow = [
      { text: `${i + 1}`, fontSize: 10 },
      {
        text: `${gradCollection[h.degree - 1].title}`,
        fontSize: 10,
        noWrap: true,
      },
      {
        text: `${
          h.contractDate ? createFullDateString(new Date(h.contractDate)) : ""
        }`,
        fontSize: 10,
        noWrap: true,
      },
      { text: `${h.memberNumber}`, fontSize: 10 },
      { text: `${h.lastName}`, fontSize: 10 },
      { text: `${h.firstName}`, fontSize: 10 },
      {
        text: `${
          h.birthdate ? createFullDateString(new Date(h.birthdate)) : ""
        }`,
        // text: `${
        //   h.lastTesting ? createFullDateString(new Date(h.lastTesting)) : ""
        // }`,
        fontSize: 10,
      },
      { text: "" },
    ];
    dataRows.push(newRow);
  });

  dataRows.unshift(tH);

  // Docu: https://pdfmake.github.io/docs/0.1/
  var docDefinition = {
    content: [
      { text: "Standortübersicht", style: "header" },
      {
        text: `Standort: ${siteName}`,
        style: "subheader",
      },
      `Datum: ${createFullDateString(new Date())}`,
      {
        style: "tableExample",
        table: {
          body: dataRows,
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
    defaultStyle: {
      // alignment: 'justify'
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
