export const substituteNonSwiftLatin = (input) => {
  const substitutions = {
    ä: "ae",
    ö: "oe",
    ü: "ue",
    ß: "ss",
    à: "a",
    á: "a",
    â: "a",
    ã: "a",
    å: "a",
    ç: "c",
    ć: "c",
    è: "e",
    é: "e",
    ê: "e",
    ë: "e",
    ì: "i",
    í: "i",
    î: "i",
    ï: "i",
    ñ: "n",
    ò: "o",
    ó: "o",
    ô: "o",
    õ: "o",
    ù: "u",
    ú: "u",
    û: "u",
    ý: "y",
    ÿ: "y",
    // Add more substitutions as needed
  };

  return input.replace(/[\u00C0-\u00FF]/g, function (char) {
    return substitutions[char] || char;
  });
};
